import { FC, Fragment, useContext, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { clearMenu, clearProfile, clearToken, getProfile } from '../../utils/Auth.utils';
import Logo from '../../assets/infinity-black.png';
import { history } from '../../App';
import { AuthContext } from '../../context/auth/Auth.Provider';

const Header2: FC = () => {
    const authContext = useContext(AuthContext);
    const user = {
        name: '',
        email: authContext.token.username,
        imageUrl: '',
    };

    const navigationArray = [
        { name: 'Home', href: '/home', current: true },
        { name: 'Start Here', href: 'start-here', current: false },
        { name: 'Users', href: '/users', current: false },
        { name: 'Authors', href: '/authors', current: false },
        { name: 'Meditations', href: '/meditations', current: false },
        { name: 'Yoga', href: '/yoga', current: false },
        { name: 'Courses', href: '/courses', current: false },
        { name: 'Meditation Categories', href: '/meditation-categories', current: false },
        { name: 'Yoga Categories', href: '/yoga-categories', current: false },
    ];
    const userNavigation = [{ name: 'Sign out', href: '#', id: 'logout' }];

    const [navigation, setNavigation] = useState<any>(navigationArray);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }
    const handleMenuEvent = (e) => {
        if (e?.id === 'logout') {
            clearToken();
            clearProfile();
            clearMenu();
            window.location.reload();
        }
    };
    const handleMenuclick = (item) => {
        const ix = navigationArray.findIndex((n) => n.name === item.name);
        localStorage.setItem('menu', ix.toString());
        setNavigation(
            [...navigation].map((n) => {
                n.current = n.name === item.name ? true : false;
                return n;
            })
        );
        history.push(item.href);
    };
    const getActiveMenu = (key: string): number => {
        const pathname = window.location.pathname; //returns the current url minus the domain name
        if (pathname === '/') {
            localStorage.setItem('menu', '0');
            return 0;
        }
        return Number(localStorage.getItem(key));
    };

    return (
        <Disclosure as="nav" className="bg-white">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                <div className="flex flex-shrink-0 gap-8 items-center">
                                    <img className="w-14" src={Logo} alt="CoCreate" />
                                    <span className="md:hidden text-lg font-bold">Co-Create CMS</span>
                                </div>
                                <div className="hidden lg:block">
                                    <div className="ml-10 flex items-baseline space-x-4 cursor-pointer">
                                        {navigationArray.map((item, index) => (
                                            <div
                                                key={item.name}
                                                onClick={(e) => handleMenuclick(item)}
                                                className={classNames(
                                                    index === getActiveMenu('menu')
                                                        ? 'bg-gray-900 text-white'
                                                        : 'text-black hover:underline hover:underline-offset-4',
                                                    'px-3 py-1 rounded-md text-md font-medium'
                                                )}
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="hidden lg:block">
                                <div className="ml-4 flex items-center md:ml-6">
                                    {/* Profile dropdown */}
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <Menu.Button className="flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                <span className="sr-only">Open user menu</span>
                                                <UserCircleIcon className="block h-8 w-8" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {userNavigation.map((item) => (
                                                    <Menu.Item key={item.name}>
                                                        {({ active }) => (
                                                            <>
                                                                <div
                                                                    onClick={() => {
                                                                        handleMenuEvent(item);
                                                                    }}
                                                                    className="block px-4 py-2 text-sm text-black font-bold cursor-pointer"
                                                                >
                                                                    {item.name}
                                                                    <span className="text-xs ml-1 font-light">
                                                                        ({user.email})
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                            <div className="-mr-2 flex lg:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-black">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="lg:hidden">
                        <div className="bg-gray-100 rounded-md">
                            <div className="space-y-1 p-2 pb-3 sm:px-3">
                                {navigationArray.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="div"
                                        onClick={(e) => handleMenuclick(item)}
                                        className={classNames(
                                            item.current
                                                ? 'bg-gray-900 text-white'
                                                : 'text-black hover:bg-gray-700 hover:text-white',
                                            'block px-3 py-2 rounded-md text-base font-medium'
                                        )}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                            <div className="p-2">
                                <div className="border-t border-gray-700 pt-4 pb-3">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 ml-3">
                                            <UserCircleIcon className="block h-8 w-8" aria-hidden="true" />
                                            {/* <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" /> */}
                                        </div>
                                        <div className="ml-3">
                                            {/* <div className="text-base font-medium leading-none text-black">{user.name}</div> */}
                                            <div className="text-sm font-medium leading-none text-black">
                                                {user.email}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 space-y-1 px-2">
                                        {userNavigation.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="div"
                                                onClick={() => {
                                                    handleMenuEvent(item);
                                                }}
                                                className="block rounded-md px-3 py-2 text-base font-medium text-black"
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default Header2;
