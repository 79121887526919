import { ApolloClient as Appollo, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';

import { onError } from '@apollo/client/link/error';
import { message } from 'antd';
import apolloLogger from 'apollo-link-logger';

import { clearMenu, clearProfile, clearToken, getToken, setToken } from './Auth.utils';
import { refreshToken } from '../context/auth/apollo/Auth.Service';

const GRAPH_ENDPOINT = `${process.env.REACT_APP_API_URL}/graphql/`;

export const ApolloClient = () => {
    const errorLink = onError(({ response, operation, forward }) => {
        if (response && response.errors) {
            if (response.errors[0] && response.errors[0].extensions?.response?.statusCode === 401) {
                console.log('Token Expired!!! ❌ ❌ ❌');
                refreshToken()
                    .then((resp: any) => {
                        setToken(resp.jwtBearer);
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    })
                    .catch((e) => {
                        console.log('e', e);
                        clearToken();
                        clearMenu();
                        clearProfile();
                        window.location.reload();
                    });
            }

            // const errors = response.errors.map((x) => x.details);
        }
    });

    const responseLink = new ApolloLink((operation, forward) => {
        return forward(operation).map((response: any) => {
            if (response.errors) {
                const errors = response.errors.map((x: any) => x.details);
                const messageString = errors.join('. ');
                if (response.errors[0].internalCode === 4005) {
                    message.error(
                        'This email is already associated with an existing account. If you already have an account, please login.'
                    );
                    return response;
                }
            }

            return response;
        });
    });

    const token = getToken();
    // console.log("🚀 ", token);

    const link = [];
    link.push(responseLink);

    link.push(errorLink);
    if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'test') {
        link.push(apolloLogger);
    }
    link.push(
        new HttpLink({
            uri: GRAPH_ENDPOINT,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    );

    const client = new Appollo({
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                errorPolicy: 'all',
            },
            mutate: {
                errorPolicy: 'all',
            },
            watchQuery: {
                errorPolicy: 'all',
            },
        },
        link: ApolloLink.from(link),
    });

    return client;
};
