import gql from 'graphql-tag';

export const loginMutation = gql`
  mutation Login($input: Login!) {
    Login(input: $input) {
      ...loginFields
    }
  }

  fragment loginFields on LoginResponse {
    email
    jwtBearer
    jwtRefresh
  }
`;

export const loggedInUserQuery = gql`
  query LoggedInUser {
    ...LoggedInUserQuery
  }

  fragment LoggedInUserQuery on Query {
    LoggedInUser {
      id
      ...userFields
      StripePaymentCards
      ...userAddress
    }
  }

  fragment entityFields on Entity {
    id
    createdAt
    updatedAt
    __typename
  }

  fragment userFields on User {
    ...entityFields
    ...loggedInUserProfile
  }

  fragment loggedInUserProfile on User {
    profile {
      ...loggedInUserProfileFields
    }
  }

  fragment loggedInUserProfileFields on Profile {
    firstName
    lastName
    email
    handle
    description
    stripeCustomerId
    ...loggedInUserProfileMedia
  }

  fragment loggedInUserProfileMedia on Profile {
    media {
      ...loggedInUserPhotoFields
    }
  }

  fragment loggedInUserPhotoFields on Media {
    ...entityFields
    mediaType
    pending
    url
    hasAnyReports
    moderationInfo {
      isAppropriate
      message
    }
    additionalAssets {
      name
      storedAs
      width
      height
    }
  }

  fragment addressFields on Address {
    __typename
    country
    state
    zip
    city
    street
  }

  fragment userAddress on User {
    address {
      ...addressFields
    }
  }
`;

export const profileViewQuery = (pageSize: number, pageNumber: number) => {
  return gql`
    query LoggedInUser {
      ...ProfileViewLoggedInUserQuery
    }

    fragment ProfileViewLoggedInUserQuery on Query {
      LoggedInUser {
        id
        ...profileUserFields
        StripePaymentCards
        ...userAddress
      }
    }

    fragment entityFields on Entity {
      id
      createdAt
      updatedAt
      __typename
    }

    fragment profileUserFields on User {
      ...entityFields
      ...profileViewProfile
    }

    fragment profileViewProfile on User {
      profile {
        ...profileViewFields
      }
    }

    fragment profileViewFields on Profile {
      firstName
      lastName
      email
      handle
      description
      stripeCustomerId
      ...profileViewProfileMedia
    }

    fragment profileViewProfileMedia on Profile {
      media {
        ...profileViewPhotoFields
      }
    }

    fragment profileViewPhotoFields on Media {
      ...entityFields
      mediaType
      pending
      url
      hasAnyReports
      moderationInfo {
        isAppropriate
        message
      }
      additionalAssets {
        name
        storedAs
        width
        height
      }
    }

    fragment addressFields on Address {
      __typename
      country
      state
      zip
      city
      street
    }

    fragment userAddress on User {
      address {
        ...addressFields
      }
    }
  `;
};

export const loggedInUserInfoQuery = gql`
  query LoggedInUser {
    ...LoggedInUserInfoQuery
  }

  fragment LoggedInUserInfoQuery on Query {
    LoggedInUser {
      id
      ...loggedInUserInfoFields
      StripePaymentCards
      ...userAddress
    }
  }

  fragment entityFields on Entity {
    id
    createdAt
    updatedAt
    __typename
  }

  fragment loggedInUserInfoFields on User {
    ...entityFields
    ...loggedInUserInforProfile
  }

  fragment loggedInUserInforProfile on User {
    profile {
      ...loggedInUserInfoProfileFields
    }
  }

  fragment loggedInUserInfoProfileFields on Profile {
    firstName
    lastName
    email
    handle
    description
    stripeCustomerId
    ...loggedInUserInfoProfileMedia
  }

  fragment loggedInUserInfoProfileMedia on Profile {
    media {
      ...loggedInUserInfoPhotoFields
    }
  }

  fragment loggedInUserInfoPhotoFields on Media {
    ...entityFields
    mediaType
    pending
    url
    hasAnyReports
    moderationInfo {
      isAppropriate
      message
    }
    additionalAssets {
      name
      storedAs
      width
      height
    }
  }

  fragment addressFields on Address {
    __typename
    country
    state
    zip
    city
    street
  }

  fragment userAddress on User {
    address {
      ...addressFields
    }
  }
`;

export const registerMutation = gql`
  mutation Register($input: UserCreate!) {
    Register(input: $input) {
      ...messageFields
    }
  }

  fragment messageFields on MessageResponse {
    message
  }
`;

export const requestEmailVerificationMutation = gql`
  mutation RequestEmailVerification($input: RequestEmailVerificationInput!) {
    RequestEmailVerification(input: $input) {
      ...messageFields
    }
  }

  fragment messageFields on MessageResponse {
    message
    
  }
`;

export const verifyEmailMutation = gql`
  mutation($input: VerifyEmailInput!) {
    VerifyEmail(input: $input) {
      ...messageFields
    }
  }

  fragment messageFields on MessageResponse {
    message
    
  }
`;

export const requestPasswordChangeMutation = gql`
  mutation RequestPasswordChange($input: RequestPasswordChangeInput!) {
    RequestPasswordChange(input: $input) {
      ...messageFields
    }
  }

  fragment messageFields on MessageResponse {
    message
    
  }
`;

export const changePasswordMutation = gql`
  mutation($input: ChangePasswordInput!) {
    ChangePassword(input: $input) {
      ...messageFields
    }
  }

  fragment messageFields on MessageResponse {
    message
    
  }
`;

export const checkTokenMutation = gql`
  mutation($input: CheckTokenInput!) {
    CheckToken(input: $input)
  }
`;

export const refreshTokenMutation = gql`
  mutation($input: ExchangeRefreshTokenInput!) {
    ExchangeRefreshToken(input: $input) {
      jwtBearer
    }
  }
`;
